<template>
  <section class="section keep-footer-off-page">
    <div class="container">
      <div class="columns is-centered">
        <!-- Card -->
        <div class="column cardSize is-two-thirds box p-5">
          <!-- Main Content -->

          <!-- SmartTag -->
          <h1 class="pb-2 heading has-text-centered">Project</h1>
          <div class="tag_box">
            <SmartTag
              class="py-3"
              :tags="mainTagInput"
              :isLoading="isLoading"
            />
          </div>
          <hr />

          <!-- Progress Bar -->
          <h1 class="heading has-text-centered mb-5">Progress</h1>
          <ProgressBar
            :objectivesLog="project.objectivesLog"
            :isLoading="isLoading"
          />
          <hr />

          <!-- Skills -->
          <h1 class="heading has-text-centered mb-5">Skills Required</h1>
          <div class="tag_box">
            <b-taglist>
              <b-tag
                v-for="skill in project.skills"
                :key="skill"
                type="is-skill"
                >{{ skill }}</b-tag
              >
            </b-taglist>
          </div>
          <hr />

          <!-- Description -->
          <h1 class="heading has-text-centered mb-5">Description</h1>
          <DescriptionSection
            :isLoading="isLoading"
            :text="project.description"
          />
          <hr />

          <!-- Objectives -->
          <h1 class="heading has-text-centered mb-5">Tasks</h1>
          <ObjectivesTable
            :objectivesLog="project.objectivesLog"
            :deleteableRows="false"
            :showCompleted="true"
            :showDetails="true"
            :isLoading="isLoading"
          />
          <hr />

          <!-- People -->
          <h1 class="heading has-text-centered">People</h1>
          <nav class="level mt-5">
            <!-- Lead -->
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Lead</p>

                <SmartTag
                  v-if="!isEmpty(project.team)"
                  size="is-small"
                  :tags="[
                    { type: 'is-name', value: project.createdBy },
                    { type: 'is-role', value: project.team[project.createdBy] },
                  ]"
                />
              </div>
            </div>
            <!-- Setter -->
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">Setter</p>
                <SmartTag
                  v-if="!isEmpty(project.team)"
                  size="is-small"
                  :tags="[
                    { type: 'is-name', value: project.lead },
                    { type: 'is-role', value: project.team[project.lead] },
                  ]"
                />
              </div>
            </div>
          </nav>
          <!-- Team -->
          <div v-if="!isEmpty(project.team)">
            <h1 class="heading has-text-centered">Project team members</h1>
            <nav class="level"></nav>
          </div>
          <hr />

          <!-- Changelog-->
          <h1 class="heading has-text-centered mb-5">Changelog</h1>
          <ChangelogTable
            :changelog="project.changelog"
            :showDetails="true"
            :isLoading="isLoading"
          />
          <hr />

          <!-- Edit button -->
          <div class="tag_box" v-if="role == 'editor'">
            <b-button
              icon-pack="fas"
              icon-left="fas fa-edit"
              class="my-5"
              type="is-primary"
              outlined
              @click="editPageRequest()"
              >Edit</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ChangelogTable from "@/components/ChangelogTable.vue";
import DescriptionSection from "@/components/DescriptionSection.vue";
import ObjectivesTable from "@/components/ObjectivesTable.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import SmartTag from "@/components/SmartTag.vue";
import isEmpty from "lodash/isEmpty";

export default {
  name: "ViewProject",

  components: {
    ChangelogTable,
    ObjectivesTable,
    ProgressBar,
    SmartTag,
    DescriptionSection,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    role: {
      type: String,
      default() {
        return "viewer";
      },
      required: false,
    },
    project: {
      type: Object,
      default() {
        return { projectName: "", name: "", skills: [""], deadlineDate: "" };
      },
      required: true,
    },
  },

  data() {
    return {
      mainSkill: "",
    };
  },

  methods: {
    isEmpty,
    editPageRequest() {
      window.scrollTo(0, 0);
      this.$emit("editPageRequest");
    },
  },
  computed: {
    mainTagInput() {
      return [
        { type: "is-project", value: this.project.projectName },
        { type: "is-skill", value: this.project.skills },
        { type: "is-date", value: this.project.deadlineDate },
      ];
    },
  },
};
</script>

<style scoped>
.tag_box {
  display: flex;
  justify-content: center;
}
.cardSize {
  max-width: 750px;
}
</style>
