<template>
  <ViewProject
    @editPageRequest="showEditPage()"
    :project="new_prjct.project"
    :role="new_prjct.role"
    :isLoading="dataIsLoading"
  />
</template>

<script>
import axiosRetry from "axios-retry";
import ViewProject from "../components/ViewProject.vue";
import store from "../store";

export default {
  name: "single-project-view",

  components: {
    ViewProject,
  },

  data() {
    return {
      dataIsLoading: false,
      errorInGetRequest: false,
      pid: "",
      new_prjct: {},
      currentPage: "view",
    };
  },

  methods: {
    startLoadingAnimations() {
      this.dataIsLoading = true;
    },
    stopLoadingAnimations() {
      this.dataIsLoading = false;
    },
    showEditPage() {
      this.currentPage = "edit";
    },
    showViewPage() {
      this.currentPage = "view";
    },
    async getTask() {
      const idToken = store.state.auth.user.accessToken;
      let config = {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      };
      const baseUrl = "https://solute-python3.onrender.com";
      const subdomain = "/project/";
      const fullUrl = baseUrl + subdomain + this.pid;

      try {
        const project = await this.$axios.get(fullUrl, config);
        console.log("Project Data");
        console.log(project.data);
        this.new_prjct = project.data;
      } catch (error) {
        this.errorInGetRequest = true;
        if (error.response) {
          // Request made but the server responded with an error
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // Request made but no response is received from the server.
          console.log(error.request);
        } else {
          // Error occured while setting up the request
          console.log("Error", error.message);
        }
      }
      // Change loading state.
      this.stopLoadingAnimations();
    },
  },
  created() {
    // Set Exponential back-off retry delay between requests
    axiosRetry(this.$axios, { retryDelay: axiosRetry.exponentialDelay });
    this.pid = this.$route.params.pid;
    this.startLoadingAnimations();
    this.getTask();
  },
};
</script>
