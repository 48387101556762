var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section keep-footer-off-page"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column cardSize is-two-thirds box p-5"},[_c('h1',{staticClass:"pb-2 heading has-text-centered"},[_vm._v("Project")]),_c('div',{staticClass:"tag_box"},[_c('SmartTag',{staticClass:"py-3",attrs:{"tags":_vm.mainTagInput,"isLoading":_vm.isLoading}})],1),_c('hr'),_c('h1',{staticClass:"heading has-text-centered mb-5"},[_vm._v("Progress")]),_c('ProgressBar',{attrs:{"objectivesLog":_vm.project.objectivesLog,"isLoading":_vm.isLoading}}),_c('hr'),_c('h1',{staticClass:"heading has-text-centered mb-5"},[_vm._v("Skills Required")]),_c('div',{staticClass:"tag_box"},[_c('b-taglist',_vm._l((_vm.project.skills),function(skill){return _c('b-tag',{key:skill,attrs:{"type":"is-skill"}},[_vm._v(_vm._s(skill))])}),1)],1),_c('hr'),_c('h1',{staticClass:"heading has-text-centered mb-5"},[_vm._v("Description")]),_c('DescriptionSection',{attrs:{"isLoading":_vm.isLoading,"text":_vm.project.description}}),_c('hr'),_c('h1',{staticClass:"heading has-text-centered mb-5"},[_vm._v("Tasks")]),_c('ObjectivesTable',{attrs:{"objectivesLog":_vm.project.objectivesLog,"deleteableRows":false,"showCompleted":true,"showDetails":true,"isLoading":_vm.isLoading}}),_c('hr'),_c('h1',{staticClass:"heading has-text-centered"},[_vm._v("People")]),_c('nav',{staticClass:"level mt-5"},[_c('div',{staticClass:"level-item has-text-centered"},[_c('div',[_c('p',{staticClass:"heading"},[_vm._v("Lead")]),(!_vm.isEmpty(_vm.project.team))?_c('SmartTag',{attrs:{"size":"is-small","tags":[
                  { type: 'is-name', value: _vm.project.createdBy },
                  { type: 'is-role', value: _vm.project.team[_vm.project.createdBy] },
                ]}}):_vm._e()],1)]),_c('div',{staticClass:"level-item has-text-centered"},[_c('div',[_c('p',{staticClass:"heading"},[_vm._v("Setter")]),(!_vm.isEmpty(_vm.project.team))?_c('SmartTag',{attrs:{"size":"is-small","tags":[
                  { type: 'is-name', value: _vm.project.lead },
                  { type: 'is-role', value: _vm.project.team[_vm.project.lead] },
                ]}}):_vm._e()],1)])]),(!_vm.isEmpty(_vm.project.team))?_c('div',[_c('h1',{staticClass:"heading has-text-centered"},[_vm._v("Project team members")]),_c('nav',{staticClass:"level"})]):_vm._e(),_c('hr'),_c('h1',{staticClass:"heading has-text-centered mb-5"},[_vm._v("Changelog")]),_c('ChangelogTable',{attrs:{"changelog":_vm.project.changelog,"showDetails":true,"isLoading":_vm.isLoading}}),_c('hr'),(_vm.role == 'editor')?_c('div',{staticClass:"tag_box"},[_c('b-button',{staticClass:"my-5",attrs:{"icon-pack":"fas","icon-left":"fas fa-edit","type":"is-primary","outlined":""},on:{"click":function($event){return _vm.editPageRequest()}}},[_vm._v("Edit")])],1):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }